import React from "react"
import styled from "styled-components"
import { breakpoints, spacers } from "../styles/styles"

const CenteredTextDiv = styled.div`
    text-align: center;
    max-width: 800px;
    margin: ${spacers.m}px auto ${spacers.m}px;

    @media screen and (min-width: ${breakpoints.m}px) {
        margin: ${spacers.m}px auto ${spacers.l}px;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        margin: ${spacers.l}px auto ${spacers.l*2}px;
    }

    &.noBottomSpacing {
        margin-bottom: ${spacers.m}px;
    }
`

const CenteredText = ({children, noBottomSpacing}) => <CenteredTextDiv className={noBottomSpacing ? "noBottomSpacing" : ""}>
    {children}
</CenteredTextDiv>

export default CenteredText