import React from "react"
import styled from "styled-components"
import { colors, spacers, breakpoints } from "../styles/styles"

const DotsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: ${spacers.m}px 0;

    > div {
        width: 62px;
        height: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > div {
            height: 10px;
            width: 10px;
            border-radius: 10px;
            background: ${colors.main}
        }
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        margin: ${spacers.l}px 0;
    }
`

const Dots = () => <DotsDiv>
    <div>
        <div></div> 
        <div></div>
        <div></div>
    </div>
</DotsDiv>

export default Dots