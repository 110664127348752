import React from "react"
import IndexLayout from "../layouts/indexLayout"
import Wrapper from "../components/Wrapper"
import HalfFlex from "../components/HalfFlex"
import styled from "styled-components"
import CenteredText from "../components/CenteredText"
import Dots from "../components/Dots"
import Feature from "../components/Feature"
import {breakpoints, spacers} from "../styles/styles"

const FeaturesDiv = styled.div`
  margin: ${spacers.m}px 0;

  @media screen AND (min-width: ${breakpoints.l}px) {
  margin: ${spacers.l}px 0;

  }
`

const BackgroundDiv = styled.div`
  background: url('/assets/img/AdobeStock_178245578.jpeg');
  background: url('/assets/img/20161009_124846.jpg');
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  height: 40vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2, h1 {
    color: white;
    text-align: center;
  }
`

const BackgroundDiv2 = styled.div`
  background: url('/assets/img/DSC_1593.JPG');
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  height: 40vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2, h1 {
    color: white;
    text-align: center;
  }
`

const PageInfo = {
    desc: "Pension in zentraler Lage direkt am Markt mit modern eingerichteten Zimmern und Ferienwohnungen für 1 – 4 Personen",
    keywords: "übernachtung, pension, erzgebirge, zimmer, zschopau, hotel, ferienwohnung, zur altdeutschen, einzelzimmer, doppelzimmer",
    title: 'Pension "Zur Altdeutschen"'
}

export default function Home() {
  return <IndexLayout pageInfo={PageInfo} headerImg="/assets/img/AdobeStock_178245578.jpeg" isActive="home">
     <Wrapper>
       <CenteredText noBottomSpacing>
        <h2 class="h1">Ein herzliches "Glück auf!"</h2>
        <p>
          Willkommen im Erzgebirge!<br />Burgen und Schlösser in der Umgebung sowie andere Sehenswürdigkeiten laden zum Wandern oder zu Radtouren ein. Museen und Besucherbergwerke erzählen viel über die Bergbaugeschichte der Region. Orte des international geschätzen Kunsthandwerks sind u.a. Seiffen, Grünhainichen und Neuhausen.
        </p>
        <Dots />
      </CenteredText>
     </Wrapper>
   <BackgroundDiv>
       <h2 class="h1">Willkommen in Zschopau</h2>
   </BackgroundDiv>
    <Wrapper>
      <CenteredText noBottomSpacing>
        <p>
          Genießen Sie unsere ruhige und doch zentrale Lage direkt am Markt. In unmittelbarer Nähe finden Sie Restaurants, Bäckereien, Cafès, sowie ein Naturkost- und Drogeriegeschäft und nur ein kurzer Fußweg entfernt: das Schloss Wildeck mit Museum, Aussichtsturm und Kinderspielplatz.
Wussten Sie übrigens, dass hier in Zschopau die weltgrößte Motorradfabrik stand?
        </p>
        <Dots />
      </CenteredText>
    </Wrapper>
    <BackgroundDiv2>
       <h1>Willkommen in unserer Pension</h1>
   </BackgroundDiv2>
   <Wrapper>
     <HalfFlex noBottomSpacing>
      <div className="m2">
        <FeaturesDiv>
          <Feature icon="/assets/icons/parkplatz.svg">Parkmöglichkeit</Feature>
          <Feature icon="/assets/icons/map.svg">Zentrale Lage</Feature>
          <Feature icon="/assets/icons/bett-3.svg">Ferienwohnungen</Feature>
        </FeaturesDiv>
      </div>
      <div className="vertSpacingMobile m1">
        <CenteredText noBottomSpacing>
          <p>
            Lassen Sie den Alltag hinter sich!<br />
            Liebevoll eingerichtete Zimmer und Ferienwohnungen erwarten Sie.
            Wir glauben, ein idealer Ort zur Erholung oder für eine Entdeckungsreise ins Erzgebirge zu sein.<br />
            Wir freuen uns auf Sie!
          </p>
        </CenteredText>
      </div>
    </HalfFlex>
   </Wrapper>
  </IndexLayout>
}
