import React from "react"
import styled from "styled-components"
import { spacers, colors, breakpoints } from "../styles/styles"

const FeatureDiv = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr;
    height: 50px;
    grid-column-gap: ${spacers.m}px;
    margin-bottom: ${spacers.s}px;

    div {
        display: flex;
        flex-direction: column;
        height: 50px;
        justify-content: center;

        h3 {
            display: block;
            color: ${colors.main};
            margin: 0;
        }

        img {
            display: block;
        }
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        margin-left: ${spacers.m}px;
        margin-bottom: ${spacers.m}px;

    }

    @media screen AND (min-width: ${breakpoints.l}px) {
      margin-left: ${spacers.l}px
    }
`

const Feature = ({children, icon}) => <FeatureDiv>
    <div>
        <img src={icon} alt="feature-icon" />
    </div>
    <div>
        <h3>{children}</h3>
    </div>
</FeatureDiv>

export default Feature